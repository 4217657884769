import React, { useState } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-inspiration/src/components/layout';
import { Link } from 'gatsby';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

import gatsbyConfig from '@accrosoft-ltd/accropress-theme-inspiration/theme-config';

import '@accrosoft-ltd/accropress-theme-inspiration/src/components/wig-bundle.scss';

import Breadcrumb from '@accrosoft-ltd/accropress-websites-components/dist/esm/breadcrumb';


import loadable from '@loadable/component';
const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader'),
);

require('es6-promise').polyfill();

export default function StandardPagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;

  const returnParentArray = (id) => {
    if (id) {
      if (
        navResultData &&
        navResultData.menu &&
        navResultData.menu.length > 0
      ) {
        let parentArrayData = navResultData.menu.filter(function (item) {
          return item.id === id;
        });

        return parentArrayData[0];
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  let pageParentDataArray = {};

  if (contentMetaData && contentMetaData.parent && contentMetaData.parent.id) {
    pageParentDataArray = returnParentArray(contentMetaData.parent.id);
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  console.log('integrators data: ');
  console.log(integratorData);

  console.log('content meta data: ');
  console.log(contentMetaData);

  console.log('content data data: ');
  console.log(contentDataData);

  return (
    <Layout>
      <SEO
        defaultSiteData={gatsbyConfig.siteMetadata}
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          <div className="banner page">
            <div
              className="hero"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: `
                url("${
                  contentDataData[0].children[0] &&
                  contentDataData[0].children[0].value
                    ? returnResizedImageURL(
                        { w: 1900 },
                        contentDataData[0].children[0].value,
                      )
                    : '/banner.jpg'
                }")`,
              }}
            ></div>
            <div className="brush-stoke-banner"></div>
          </div>
          <div className="mainContentWrapper page">
            <section className="app-content">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-9-l col-7-m pageMain">
                    {contentDataData[0].children[1] &&
                      contentDataData[0].children[1].value && (
                        <h1 data-typesense-field="title">
                          {contentDataData[0].children[1].value}
                        </h1>
                      )}

                      <Breadcrumb
                        contentMetaData={contentMetaData}
                        contentDataData={contentDataData}
                        integratorData={integratorData}
                        navResultData={navResultData}
                      />
                      
                    <WigLoader
                      data-typesense-field="description"
                      weducWebsitesID={config.weducWebsitesID}
                      weducContainer={config.weducContainerID}
                      weducEntity={config.weducEntityID}
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="0"
                      contentDataChild="2"
                      AccroPressProdEndpoint={config.apiEndpoint}
                      AccroPressLocalEndpoint={config.apiEndpoint}
                      integratorID={config.integratorID}
                      integratorAccessToken={config.integratorAccessToken}
                      imageFormatting={
                        process.env.IMAGE_FORMATTING_SECRET ||
                        process.env.GATSBY_IMAGE_FORMATTING_SECRET
                      }
                      accroPressSiteID={config.accroPressSiteID}
                    />
                  </div>
                  <div className="col-12 col-3-l col-5-m">
                    {contentMetaData && (contentMetaData.title != 'Contact') && (contentMetaData.title != 'Vacancies') ? (
                      <div className="pageSidebar">
                        {contentMetaData.children &&
                        contentMetaData.children.length > 0 ? (
                          <h3>In This Section</h3>
                        ) : !contentMetaData.parent ? (
                          <h3>In This Section</h3>
                        ) : (
                          <h3>In This Section</h3>
                        )}
                        <ul>
                          {contentMetaData.children &&
                            contentMetaData.children.length > 0 && (
                              <li>
                                <Link
                                  className="active parent-nav-item"
                                  to={contentMetaData.slug}
                                >
                                  {contentMetaData.title}
                                </Link>
                              </li>
                            )}
                          {contentMetaData.children &&
                          contentMetaData.children.length > 0 ? (
                            contentMetaData.children
                              .sort(dynamicSort('order'))
                              .map((item, i) => (
                                <li key={i}>
                                  <Link
                                    key={i}
                                    className={
                                      contentMetaData.slug &&
                                      contentMetaData.slug === item.slug
                                        ? 'active parent-nav-item'
                                        : 'child-nav-item'
                                    }
                                    to={`${
                                      item.parentSlug
                                        ? item.parentSlug + item.slug
                                        : item.slug.replace(
                                            /^\/?([^\/]+(?:\/[^\/]+)*)\/?$/,
                                            '/$1/',
                                          ) || '/'
                                    }`}
                                  >
                                    {item.title}
                                  </Link>
                                </li>
                              ))
                          ) : contentMetaData && contentMetaData.parent ? (
                            <>
                              {pageParentDataArray &&
                                pageParentDataArray.title > 0 && (
                                  <li>
                                    <Link
                                      className="active parent-nav-item"
                                      to={pageParentDataArray.slug}
                                    >
                                      {pageParentDataArray.title}
                                    </Link>
                                  </li>
                                )}
                              {pageParentDataArray &&
                                pageParentDataArray.children &&
                                pageParentDataArray.children.length > 0 &&
                                pageParentDataArray.children
                                  .sort(dynamicSort('order'))
                                  .map((item, i) => (
                                    <li key={i}>
                                      <Link
                                        key={i}
                                        className={
                                          contentMetaData.slug &&
                                          contentMetaData.slug === item.slug
                                            ? 'active parent-nav-item'
                                            : 'child-nav-item'
                                        }
                                        to={`${
                                          item.parentSlug
                                            ? item.parentSlug + item.slug
                                            : item.slug.replace(
                                                /^\/?([^\/]+(?:\/[^\/]+)*)\/?$/,
                                                '/$1/',
                                              ) || '/'
                                        }`}
                                      >
                                        {item.title}
                                      </Link>
                                    </li>
                                  ))}
                            </>
                          ) : (
                            <>
                              {navResultData &&
                                navResultData.menu &&
                                navResultData.menu.length > 0 &&
                                navResultData.menu
                                  .sort(dynamicSort('order'))
                                  .map((item, i) => (
                                    <li>
                                      <Link
                                        className={
                                          contentMetaData.slug &&
                                          contentMetaData.slug === item.slug
                                            ? 'active parent-nav-item'
                                            : 'child-nav-item'
                                        }
                                        to={`${
                                          item.parentSlug
                                            ? item.parentSlug + item.slug
                                            : item.slug.replace(
                                                /^\/?([^\/]+(?:\/[^\/]+)*)\/?$/,
                                                '/$1/',
                                              ) || '/'
                                        }`}
                                      >
                                        {item.title}
                                      </Link>
                                    </li>
                                  ))}
                            </>
                          )}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
